
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, menuController, IonButtons } from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";

import { Calendar } from "v-calendar";
import { openToast } from "@/services/toast";
import { dateFormat } from "@/services/utils";

import appuntamentiCustomAPI from "@/custom/services/appuntamenti";

import ProgrammaLavoroDetail from "@/components/programma_lavoro/ProgrammaLavoroDetail.vue";
import ProjectDetail from "@/custom/impianti/ProjectDetail.vue";

export default {
    name: "Calendario",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        Calendar,
    },
    setup() {
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const userType = JSON.parse(localStorage.getItem("userInfo")).dipendenti_tipologia;
        console.log(userType);

        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         * ! Passing appuntamento object ProjectDetail page
         */
        async function openImpianto(appuntamento) {
            console.log(appuntamento);

            const modal = await modalController.create({
                component: ProjectDetail,
                componentProps: {
                    data: appuntamento,
                },
            });
            return modal.present();
        }

        const riferimentoImpianto = computed(() => {
            return (impianto) => {
                if (impianto.projects_codice && impianto.customers_address) {
                    return `${impianto.projects_codice} - ${impianto.customers_address}`;
                } else {
                    return " - ";
                }
            };
        });

        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            } else if (!cliente.customers_name && cliente.customers_last_name) {
                return `${cliente.customers_last_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        const indirizzoCliente = (cliente) => {
            const city = cliente.customers_city ? cliente.customers_city : "";
            const province = cliente.customers_province ? "(" + cliente.customers_province + ")" : "";
            return `${city} ${province}`;
        };

        /**
         * Print tecnici for each appuntamento
         * @param appuntamento
         */
        function formatTecnici(appuntamento) {
            //console.log(appuntamento.appuntamenti_persone);
            if (appuntamento.appuntamenti_persone) {
                const tecniciAppuntamento = Object.keys(appuntamento.appuntamenti_persone).map(function(key) {
                    return appuntamento.appuntamenti_persone[key];
                });
                return tecniciAppuntamento;
            }
            return "";
        }

        function formatAutomezzi(appuntamento) {
            if (appuntamento.appuntamenti_automezzi) {
                /* for (const [key, value] of Object.entries(appuntamento.appuntamenti_automezzi)) {
                    return `${value}`;
                } */
                const automezziAppuntamento = Object.keys(appuntamento.appuntamenti_automezzi).map(function(key) {
                    return appuntamento.appuntamenti_automezzi[key];
                });
                return automezziAppuntamento;
            }
            return "-";
        }

        /**
         * ! Get all appuntamenti
         */
        const appuntamenti = ref([]);
        async function loadAppuntamenti() {
            loading.value = true;
            try {
                //se sono type 11 nel service aggiungo il where per la multiselect persone
                const res = await appuntamentiCustomAPI.getAppuntamenti(userType, userID);
                if (res.status === 0) {
                    appuntamenti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
                }
            } catch (error) {
                appuntamenti.value = [];
                openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * ! Set calendar data
         */
        const date = ref(new Date());
        const selectedDay = ref(new Date()); // Add state to store selected day

        const setAttributes = computed(() => {
            return appuntamenti.value.map((appuntamento) => ({
                key: `appuntamento.${appuntamento.appuntamenti_id}`,
                highlight: {
                    color: "blue",
                    fillMode: "solid",
                },
                dates: appuntamento.appuntamenti_giorno,
                customData: appuntamento,
            }));
        });

        /**
         * ! Mostra solo gli appuntamenti del giorno selezioanto
         */
        const appuntamentiToShow = ref([]);
        const dayClicked = (day) => {
            selectedDay.value = day;
            appuntamentiToShow.value = [];
            //appuntamentiToShow.value = [...day.attributes];
            appuntamentiToShow.value = day.attributes.length == 0 ? [] : day.attributes.map((appuntamento) => appuntamento.customData);
        };

        onMounted(() => {
            //GET APPUNTAMENTI DATA
            loadAppuntamenti();
        });

        return {
            loading,
            add,
            refresh,
            openImpianto,
            menu,
            openMenu,
            //Appuntamenti
            loadAppuntamenti,
            dateFormat,
            appuntamenti,
            Calendar,
            date,
            selectedDay,
            dayClicked,
            setAttributes,
            appuntamentiToShow,
            riferimentoCliente,
            indirizzoCliente,
            riferimentoImpianto,
            formatTecnici,
            formatAutomezzi,
        };
    },
};
