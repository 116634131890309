import axios from "axios";

export default {
    /**
     * Get appuntamenti squadre for the logged user
     */
    async getAppuntamenti(userType, userId) {
        const data = new FormData();

        if (userType && userType === "11") {
            data.append("where[]", `appuntamenti_id IN (SELECT appuntamenti_id FROM appuntamenti_persone WHERE users_id = '${userId}')`);
        }
        data.append("limit", "300");
        data.append("orderby", "appuntamenti_giorno");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/appuntamenti", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data;
    },
};
